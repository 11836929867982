import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ActionablesComponent } from '../actionables/actionables.component';
import { UserContextService } from '../services/user-context.service';
import { HamburguerMenuComponent } from './hamburguer-menu/hamburguer-menu.component';
import { mainMenuActionables } from './main-menu-actionables';
import { MobileModalComponent } from './mobile-modal/mobile-modal.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent, IIdLabel } from '../dropdown/dropdown.component';
import { LocaleManagerService } from '../services/locale-manager.service';
import { EnumHelper } from '../services/enum-helper';
import { CommonModule } from '@angular/common';
import { AvailableLocales } from '../openapi';
import { NavigationService } from '../services/navigation.service';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'bk-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ActionablesComponent,
    HamburguerMenuComponent,
    OverlayModule,
    DropdownComponent,
    LanguageSelectorComponent,
  ],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss'
})
export class MainMenuComponent {
  actionables = mainMenuActionables(this.router, this.userContext, this.navigationService);
  overlayRef?: OverlayRef;
  languageOptions: IIdLabel<string>[] = Object.entries(EnumHelper.languageOptions).map(([key, value]) => ({
    id: key,
    label: value,
  }));
  language = LocaleManagerService.getLocale();
  isOpen = new FormControl(false);

  constructor(
    private navigationService: NavigationService,
    private modalService: ModalService,
    private userContext: UserContextService,
    private router: Router,
    public overlay: Overlay,
  ) { }

  async openMenu() {
    try {
      await this.modalService.open(MobileModalComponent);
    } finally {
      this.isOpen.setValue(false);
    }
  }

  setLanguage(locale: AvailableLocales) {
    if (locale === LocaleManagerService.getLocale()) {
      return;
    }
    LocaleManagerService.saveLocale(locale);
  }
}

import { Router } from "@angular/router";
import { IActionable, onRouteNavigation } from "../models/IActionable";
import { ManageRouteConstants, RouteConstants } from "../services/routing.constants";
import { UserContextService } from "../services/user-context.service";
import { NavigationService } from "../services/navigation.service";
import { Entitlements } from "../openapi";

export const mainMenuActionables: (router: Router, userContext: UserContextService, navigationService: NavigationService) => IActionable[] =
  (router: Router, userContext: UserContextService, navigationService: NavigationService) => {
    const iconSuffix = 'icon-right link-default-lighter';
    const customizations = 'w-100';
    return [
      {
        label: $localize`Scan code`,
        handler: () => navigationService.readQrCode(false),
        requireLogin: true,
        executing$: onRouteNavigation(router),
        customizations,
        iconPrefix: 'icon-code text-secondary',
        iconSuffix,
        requiredEntitlements: [],
      },
      {
        label: $localize`Manage`,
        url: RouteConstants.Manage,
        requireLogin: true,
        executing$: onRouteNavigation(router),
        customizations,
        iconPrefix: 'icon-list text-secondary',
        iconSuffix,
        requiredEntitlements: [],
      },
      {
        label: $localize`Calendar`,
        url: `${RouteConstants.Manage}/${ManageRouteConstants.List}/${ManageRouteConstants.Calendar}`,
        requireLogin: true,
        executing$: onRouteNavigation(router),
        customizations,
        iconPrefix: 'icon-calendar text-secondary',
        iconSuffix,
        requiredEntitlements: [],
      },
      {
        label: $localize`Pricing`,
        url: RouteConstants.Pricing,
        requireLogin: true,
        executing$: onRouteNavigation(router),
        customizations,
        iconPrefix: 'icon-plans-subscriptions text-secondary',
        iconSuffix,
        requiredEntitlements: [],
      },
      {
        label: $localize`Profile`,
        url: RouteConstants.Profile,
        requireLogin: true,
        executing$: onRouteNavigation(router),
        customizations,
        iconPrefix: 'icon-user text-secondary',
        iconSuffix,
        requiredEntitlements: [],
      },
      {
        label: $localize`Logout`,
        handler: () => userContext.signOut(),
        requireLogin: true,
        executing$: onRouteNavigation(router),
        customizations,
        iconPrefix: 'icon-log-out text-secondary',
        iconSuffix,
        requiredEntitlements: [],
      },
      {
        label: $localize`Edit plans`,
        url: RouteConstants.AdminPlans,
        requireLogin: true,
        executing$: onRouteNavigation(router),
        customizations,
        iconPrefix: 'icon-edit text-secondary',
        iconSuffix,
        requiredEntitlements: [Entitlements.ManagePlans],
      },
    ];
  };

import { Component, Input } from '@angular/core';
import { GenericControlValueAccessorDirective } from '../services/generic-control-value-acessor.directive';
import { AvailableLocales } from '../openapi';
import { IIdLabel } from '../dropdown/dropdown.component';
import { EnumHelper } from '../services/enum-helper';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'bk-language-selector',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss'
})
export class LanguageSelectorComponent extends GenericControlValueAccessorDirective<AvailableLocales> {
  @Input({ required: true }) options!: IIdLabel<string>[];
  EnumHelper = EnumHelper;

  changeLanguage(key: string) {
    const language: AvailableLocales = parseInt(key);
    this.writeValue(language);
  }
}
